import logo from './assets/images/logo.jpeg';
import emailjs from "@emailjs/browser";
import home from './assets/images/home.jpeg';
import aboutImage from './assets/images/about-us.jpeg';
import office from './assets/images/office.jpeg';
import gardening from './assets/images/gardening.jpeg'
import house from './assets/images/house.jpg';
import fumigation from './assets/images/fumigation.png';
import laundry from './assets/images/laundry.jpeg';
import nany from './assets/images/nany.jpeg'
import client1 from './assets/images/client1.jpeg';
import client2 from './assets/images/client2.jpeg'
import './App.css';
import scrollreveal from 'scrollreveal'
import { useState, useEffect, useRef } from 'react';

function App() {
  const form = useRef();

  const [submitted, setSubmitted] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form);
    console.log(e);
    // setSubmitting(true);
    emailjs.sendForm('service_sm4zgx8', 'template_9395gtj', form.current, 'opk4omcuxb63uYdDP')
      .then(res => {
        setSubmitted(true);
      }).catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: true,
    });
    sr.reveal(
      `
        #hero-area,
        #about-us,
        #services,
        #pricing,
        #call-action,
        #clients,
        #contact,
        footer
        `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []);

  return (
    <div className="App">
      <section className="navbar-area navbar-nine">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="#hero-area">
                  <img style={{ maxHeight: '100px' }} src={logo} alt="Logo" />
                </a>
                <button className="navbar-toggler" 
                type="button" 
                onClick={toggleMenu}
                aria-expanded={isMenuOpen ? "true" : "false"}
                data-bs-toggle="collapse" data-bs-target="#navbarNine"
                  aria-controls="navbarNine" aria-label="Toggle navigation">
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNine">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a className="page-scroll active" href="#hero-area">Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#about">About Us</a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#services">Services</a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#contact">Contact</a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 d-lg-block d-flex align-items-center justify-content-end">
                  <div className="d-flex flex-column align-items-end">
                    <span className="text-white">Call Us On:</span>
                    <a href='tel:+254706077475' className="main-title h5 text-white">0706077475</a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>


      {/* <!--====== NAVBAR NINE PART ENDS ======-->

      <!--====== SIDEBAR PART START ======--> */}
      <div className="sidebar-left">
        <div className="sidebar-close">
          <a className="close" href="#close"><i className="lni lni-close"></i></a>
        </div>
        <div className="sidebar-content">
          <div className="sidebar-logo">
            <a href="index.html"><img src={logo} alt="Logo" /></a>
          </div>
          <p className="text">Lorem ipsum dolor sit amet adipisicing elit. Sapiente fuga nisi rerum iusto intro.</p>
          {/* <!-- logo --> */}
          <div className="sidebar-menu">
            <h5 className="menu-title">Quick Links</h5>
            <ul>
              <li><a href="javascript:void(0)">About Us</a></li>
              <li><a href="javascript:void(0)">Our Team</a></li>
              <li><a href="javascript:void(0)">Latest News</a></li>
              <li><a href="javascript:void(0)">Contact Us</a></li>
            </ul>
          </div>
          {/* <!-- menu --> */}
          <div className="sidebar-social align-items-center justify-content-center">
            <h5 className="social-title">Follow Us On</h5>
            <ul>
              <li>
                <a href="javascript:void(0)"><i className="lni lni-facebook-filled"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i className="lni lni-linkedin-original"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i className="lni lni-youtube"></i></a>
              </li>
            </ul>
          </div>
          {/* <!-- sidebar social --> */}
        </div>
        {/* <!-- content --> */}
      </div>
      <div className="overlay-left"></div>

      {/* <!--====== SIDEBAR PART ENDS ======-->
      <!-- Start header Area --> */}
      <section id="hero-area" className="header-area header-eight">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="header-content my-5">
                <h1 className="main-heading">ONE-STOP FOR ALL YOUR DOMESTIC AND OFFICE SERVICES.</h1>
                <p className='h4'>
                  Linking you with a Diverse Team of Qualified and Professional Service Providers.
                </p>
                <div className="button">
                  <a href="javascript:void(0)" className="btn primary-btn">Get Started</a>
                  {/* <a href="https://www.youtube.com/watch?v=r44RKWyfcFw&fbclid=IwAR21beSJORalzmzokxDRcGfkZA1AtRTE__l5N4r09HcGS5Y6vOluyouM9EM"
                    className="glightbox video-button">
                    <span className="btn icon-btn rounded-full">
                      <i className="lni lni-play"></i>
                    </span>
                    <span className="text">Watch Intro</span>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="header-image">
                <img src={home} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End header Area -->

      <!--====== ABOUT FIVE PART START ======--> */}

      <section id="about-us" className="about-area about-five">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="about-image-five">
                <svg className="shape" width="106" height="134" viewBox="0 0 106 134" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="1.66654" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="1.66654" cy="132" r="1.66667" fill="#DADADA" />
                  <circle cx="16.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="16.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="16.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="16.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="16.333" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="16.333" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="16.333" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="16.333" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="16.333" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="16.333" cy="132" r="1.66667" fill="#DADADA" />
                  <circle cx="30.9998" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6665" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="30.9998" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6665" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="30.9998" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6665" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="30.9998" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6665" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="31" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6668" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="31" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6668" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="31" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6668" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="31" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6668" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="31" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6668" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="31" cy="132" r="1.66667" fill="#DADADA" />
                  <circle cx="74.6668" cy="132" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="45.6665" cy="132" r="1.66667" fill="#DADADA" />
                  <circle cx="89.3333" cy="132" r="1.66667" fill="#DADADA" />
                  <circle cx="60.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="1.66679" r="1.66667" fill="#DADADA" />
                  <circle cx="60.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="16.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="60.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="31.0001" r="1.66667" fill="#DADADA" />
                  <circle cx="60.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="45.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="60.333" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="60.3335" r="1.66667" fill="#DADADA" />
                  <circle cx="60.333" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="88.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="60.333" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="117.667" r="1.66667" fill="#DADADA" />
                  <circle cx="60.333" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="74.6668" r="1.66667" fill="#DADADA" />
                  <circle cx="60.333" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="103" r="1.66667" fill="#DADADA" />
                  <circle cx="60.333" cy="132" r="1.66667" fill="#DADADA" />
                  <circle cx="104" cy="132" r="1.66667" fill="#DADADA" />
                </svg>
                <img src={aboutImage} alt="about" />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-five-content">
                <h6 className="small-title text-lg">Learn more About Us</h6>
                <h2 className="main-title fw-bold">Get To Know About Ngarisha Hub.</h2>
                <div className="about-five-tab">
                  {/* <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button className="nav-link active" id="nav-who-tab" data-bs-toggle="tab" data-bs-target="#nav-who"
                        type="button" role="tab" aria-controls="nav-who" aria-selected="true">Who We Are</button>
                      <button className="nav-link" id="nav-vision-tab" data-bs-toggle="tab" data-bs-target="#nav-vision"
                        type="button" role="tab" aria-controls="nav-vision" aria-selected="false">our Vision</button>
                      <button className="nav-link" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history"
                        type="button" role="tab" aria-controls="nav-history" aria-selected="false">our History</button>
                    </div>
                  </nav> */}
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                      <p className='text-lg'>NgarishaHub is an online platform that connects you to our vast network of service providers for domestic and office services. We pride in offering comprehensive Services to our clients at their homes and offices.</p>
                      <p className='text-lg'>Our team comes with the experience and knowledge necessary for all domestic and office needs.</p>
                    </div>
                    <div className="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                      <p>It is a long established fact that a reader will be distracted by the readable content of a page
                        when
                        looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                        distribution of letters, look like readable English.</p>
                      <p>There are many variations of passages of Lorem Ipsum available, but the majority have in some
                        form,
                        by injected humour.</p>
                    </div>
                    <div className="tab-pane fade" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                      <p>It is a long established fact that a reader will be distracted by the readable content of a page
                        when
                        looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                        distribution of letters, look like readable English.</p>
                      <p>There are many variations of passages of Lorem Ipsum available, but the majority have in some
                        form,
                        by injected humour.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container --> */}
      </section>

      {/* <!--====== ABOUT FIVE PART ENDS ======-->

      <!-- ===== service-area start ===== --> */}
      <section id="services" className="services-area services-eight">
        {/* <!--======  Start Section Title Five ======--> */}
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h6>Services</h6>
                  <h2 className="fw-bold">Our Top Services</h2>
                  {/* <p>
                    There are many variations of passages of Lorem Ipsum available,
                    but the majority have suffered alteration in some form.
                  </p> */}
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!--======  End Section Title Five ======--> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <img src={office} alt='office-cleaning' />
                </div>
                <div className="service-content">
                  <h3>Office Cleaning</h3>
                  <p>
                    We maintain your office so you focus on business.
                  </p>
                  <p>
                    For General Cleaning, Dusting and surface sanitization, trash removal, rest-room cleaning and sanitization, window and glass cleaning, special requests.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <img src={house} alt='office-cleaning' />
                </div>
                <div className="service-content">
                  <h3>House Cleaning</h3>
                  <p>
                    We maintain your house from carpet cleaning, floor cleaning, deep cleaning, washroom cleaning, dusting, ceiling cleaning, vacuum cleaning etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <img src={fumigation} alt='office-cleaning' />
                </div>
                <div className="service-content">
                  <h3>Fumigation</h3>
                  <p>
                    We offer customised assessment, tailored treatment plan, safe and effective fumigation, Comprehensive coverage for Insects such as Cockroaches, bedbugs, termites, ants, fleas as well as fungi.
                  </p>
                  <p>We do offer post - fumigation services</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <img src={gardening} alt='office-cleaning' />
                </div>
                <div className="service-content">
                  <h3>Gardening</h3>
                  <p>
                    We provide the best gardeners for any type of landscape, from farmlands to flowerbeds.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <img src={laundry} alt='office-cleaning' />
                </div>
                <div className="service-content">
                  <h3>Laundry Services</h3>
                  <p>
                    We offer home-based Laundry that includes folding and ioning.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <img src={nany} alt='office-cleaning' />
                </div>
                <div className="service-content">
                  <h3>Nany Services</h3>
                  <p>
                    Professional handling
                    Choose either a day-burg or Live-in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===== service-area end ===== --> */}


      {/* <!-- Start Pricing  Area --> */}
      <section id="pricing" className="pricing-area pricing-fourteen">
        {/* <!--======  Start Section Title Five ======--> */}
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h6>Pricing</h6>
                  <h2 className="fw-bold">Pricing & Plans</h2>
                  <p>
                    Our Subscription plans are based on hours. Choose your plan based on your typical workload
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!--======  End Section Title Five ======--> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="pricing-style-fourteen">
                <div className="table-head">
                  <h6 className="title">Basic</h6>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and industry.</p> */}
                  <div className="price">
                    <h4 className="amount">
                      <span className="currency">KES</span>8,000<span className="duration">/mo </span>
                    </h4>
                  </div>
                </div>

                <div className="light-rounded-buttons">
                  <a href="javascript:void(0)" className="btn primary-btn-outline">
                    SUBSCRIBE
                  </a>
                </div>

                <div className="table-content">
                  <ul className="table-list">
                    <li> <i className="lni lni-checkmark-circle"></i> 1-4 hours.</li>
                    <li> <i className="lni lni-checkmark-circle"></i> 20 sessions.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="pricing-style-fourteen middle">
                <div className="table-head">
                  <h6 className="title">Half Day</h6>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and industry.</p> */}
                  <div className="price">
                    <h2 className="amount">
                      <span className="currency">KES</span>11,000<span className="duration">/mo </span>
                    </h2>
                  </div>
                </div>

                <div className="light-rounded-buttons">
                  <a href="javascript:void(0)" className="btn primary-btn">
                    SUBSCRIBE
                  </a>
                </div>

                <div className="table-content">
                  <ul className="table-list">
                    <li> <i className="lni lni-checkmark-circle"></i> 4-6 hours.</li>
                    <li> <i className="lni lni-checkmark-circle"></i> 20 sessions.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="pricing-style-fourteen">
                <div className="table-head">
                  <h6 className="title">Full Day</h6>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and industry.</p> */}
                  <div className="price">
                    <h2 className="amount">
                      <span className="currency">KES</span>15,000<span className="duration">/mo </span>
                    </h2>
                  </div>
                </div>

                <div className="light-rounded-buttons">
                  <a href="javascript:void(0)" className="btn primary-btn-outline">
                    SUBSCRIBE
                  </a>
                </div>

                <div className="table-content">
                  <ul className="table-list">
                    <li> <i className="lni lni-checkmark-circle"></i> 6-8 hours.</li>
                    <li> <i className="lni lni-checkmark-circle"></i> 20 sessions.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ End Pricing  Area --> */}



      {/* <!-- Start Cta Area --> */}
      <section id="call-action" className="call-action">
        <div className="container">
          <div className="row justify-content-evenly">
            <div className="col-xxl-9 col-xl-10 col-lg-11 col-md-12">
              <div className="inner-content justify-content-evenly text-left">
                <h2>WHY CHOOSE US</h2>
                <div className=''>
                  <h4 className='text-white'>
                    Professionalism
                  </h4>
                  <p>
                    Our team is dedicated to delivering high-quality service with attention to detail and professionalism.
                  </p>
                </div>
                <div className='mt-5'>
                  <h4 className='text-white'>
                    Customized Solutions
                  </h4>
                  <p>
                    We tailor our services to suit your schedule, budget, and specific office and domestic needs                  </p>
                </div>
                <div className='mt-5'>
                  <h4 className='text-white'>
                    Quality Assurance
                  </h4>
                  <p>
                    We conduct regular quality checks to ensure that our standards consistently meet and exceed your expectations
                  </p>
                </div>
                <div className='mt-5'>
                  <h4 className='text-white'>
                    Reliability
                  </h4>
                  <p>
                    You can count on us to consistently deliver reliable and efficient services, allowing you to focus on your core business activities                  </p>
                </div>
                {/* <div className="light-rounded-buttons">
                  <a href="javascript:void(0)" className="btn primary-btn-outline">Get Started</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Cta Area --> */}



      {/* <!-- Start Latest News Area --> */}
      {/* <div id="blog" className="latest-news-area section">
        {/* <!--======  Start Section Title Five ======--> */}
      {/* <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h6>latest news</h6>
                  <h2 className="fw-bold">Latest News & Blog</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum available,
                    but the majority have suffered alteration in some form.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <!--======  End Section Title Five ======--> */}
      {/* <div className="container"> */}
      {/* <div className="row"> */}
      {/* <div className="col-lg-4 col-md-6 col-12">
              <div className="single-news">
                <div className="image">
                  <a href="javascript:void(0)"><img className="thumb" src="assets/images/blog/1.jpg" alt="Blog" /></a>
                  <div className="meta-details">
                    <img className="thumb" src="assets/images/blog/b6.jpg" alt="Author" />
                    <span>BY TIM NORTON</span>
                  </div>
                </div>
                <div className="content-body">
                  <h4 className="title">
                    <a href="javascript:void(0)"> Make your team a Design driven company </a>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard.
                  </p>
                </div>
              </div>
            </div> */}
      {/* <div className="col-lg-4 col-md-6 col-12"> */}
      {/* <div className="single-news">
                <div className="image">
                  <a href="javascript:void(0)"><img className="thumb" src="assets/images/blog/2.jpg" alt="Blog" /></a>
                  <div className="meta-details">
                    <img className="thumb" src="assets/images/blog/b6.jpg" alt="Author" />
                    <span>BY TIM NORTON</span>
                  </div>
                </div>
                <div className="content-body">
                  <h4 className="title">
                    <a href="javascript:void(0)">
                      The newest web framework that changed the world
                    </a>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard.
                  </p>
                </div>
              </div> */}
      {/* <!-- End Single News --> */}
      {/* </div> */}
      {/* <div className="col-lg-4 col-md-6 col-12"> */}
      {/* <!-- Single News --> */}
      {/* <div className="single-news">
                <div className="image">
                  <a href="javascript:void(0)"><img className="thumb" src="assets/images/blog/3.jpg" alt="Blog" /></a>
                  <div className="meta-details">
                    <img className="thumb" src="assets/images/blog/b6.jpg" alt="Author" />
                    <span>BY TIM NORTON</span>
                  </div>
                </div>
                <div className="content-body">
                  <h4 className="title">
                    <a href="javascript:void(0)">
                      5 ways to improve user retention for your startup
                    </a>
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard.
                  </p>
                </div>
              </div> */}
      {/* <!-- End Single News --> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div>  */}
      {/* <!-- End Latest News Area --> */}

      {/* <!-- Start Brand Area --> */}
      <div id="clients" className="brand-area section">
        {/* <!--======  Start Section Title Five ======--> */}
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h6>Meet our Clients</h6>
                  <h2 className="fw-bold">Our Awesome Clients</h2>
                  <p>
                    Here are some of the testimonials from our clients.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!--======  End Section Title Five ======--> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-12">
              <div className="clients-logos">
                <div className="single-image">
                  <img src={client2} alt="Client 2  Review" />
                  <h5 className='mb-5 mt-3'>Michael Kamau</h5>
                  <h6 className='mb-5 mt-5'>Facebook User</h6>
                  <p className='mt-5 mb-5'>Impressed by the deep clean! Punctual, respectful, and fair pricing. Will hire again!</p>
                </div>
                <div className="single-image">
                  <img src={client1} alt="Client 1  Review" />
                  <h5 className='mb-5 mt-3'>Trisha Jones</h5>
                  <h6 className='mb-5 mt-5'>Referral</h6>
                  <p className='mt-5 mb-5'>I was promptly connected to a professional cleaner. I enjoyed. I do recommend.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Brand Area --> */}

      {/* <!-- ========================= contact-section start ========================= --> */}
      <section id="contact" className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="contact-item-wrapper">
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-phone"></i>
                      </div>
                      <div className="contact-content">
                        <h4>Contact</h4>
                        <p>+254706077475</p>
                        <p>orders@ngarishahub.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-map-marker"></i>
                      </div>
                      <div className="contact-content">
                        <h4>Address</h4>
                        <p>Kasarani, Nairobi,</p>
                        <p>Kenya</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-alarm-clock"></i>
                      </div>
                      <div className="contact-content">
                        <h4>Schedule</h4>
                        <p>24 Hours / 7 Days Open</p>
                        {/* <p>Office time: 10 AM - 5:30 PM</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="contact-form-wrapper">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 mx-auto">
                    <div className="section-title text-center">
                      <span> Get in Touch </span>
                      <h2>
                        Ready to Get Started
                      </h2>
                      <span>Call us for instant free quote!</span>
                    </div>
                  </div>
                </div>
                <form ref={form} onSubmit={handleSubmit} className="contact-form">
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text" name="name" id="name" placeholder="Name" required />
                    </div>
                    <div className="col-md-6">
                      <input type="email" name="email" id="email" placeholder="Email" required />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <input type="text" name="phone" id="phone" placeholder="Phone" required />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <textarea name="message" id="message" placeholder="Type Message" rows="5"></textarea>
                    </div>
                  </div>
                  <div className="row">
                    {submitted && <span className='pb-5'>Delivered Successfully.</span>}
                    <div className="col-12">
                      <div className="button text-center rounded-buttons">
                        <button type="submit" className="btn primary-btn rounded-full">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ========================= contact-section end ========================= --> */}

      {/* <!-- ========================= map-section end ========================= --> */}
      <section className="map-section map-style-9">
        <div className="map-container">
          <object style={{ border: 0, height: "500px", width: "100%" }}
            data="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7977.791092365424!2d36.93631580000001!3d-1.232301999999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2ske!4v1708768913970!5m2!1sen!2ske"></object>
        </div>
      </section>
      {/* // < !-- ========================= map - section end ========================= --> */}

      {/* // < !--Start Footer Area-- > */}
      <footer className="footer-area footer-eleven">
        {/* <!-- Start Footer Top --> */}
        <div className="footer-top">
          <div className="container">
            <div className="inner-content">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  {/* <!-- Single Widget --> */}
                  <div className="footer-widget f-about">
                    <div className="logo">
                      <a href="index.html">
                        <img src={logo} alt="#" className="img-fluid" />
                      </a>
                    </div>
                    <p>
                      Ng'arisha Hub - Office and Home Services Simplified
                    </p>
                    <p className="copyright-text">
                      <span>© {new Date().getFullYear()} NG'ARISHA HUB Services.</span>
                    </p>
                  </div>
                  {/* <!-- End Single Widget --> */}
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  {/* <!-- Single Widget --> */}
                  <div className="footer-widget f-link">
                    <h5>Solutions</h5>
                    <ul>
                      <li><a href="#services">Domestic Cleaninfg</a></li>
                      <li><a href="#services">Office Cleaning</a></li>
                      <li><a href="#services">Fumigation</a></li>
                      <li><a href="#services">Gardening</a></li>
                      <li><a href="#services">Laundry Services</a></li>
                      <li><a href="#services">Nany Services</a></li>
                    </ul>
                  </div>
                  {/* <!-- End Single Widget --> */}
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  {/* <!-- Single Widget --> */}
                  <div className="footer-widget f-link">
                    <h5>Support</h5>
                    <ul>
                      <li><a href="javascript:void(0)">support@ngarishahub.com</a></li>
                      <li><a href="tel: +254706077475">0706077475</a></li>
                      {/* <li><a href="javascript:void(0)">Guides</a></li>
                      <li><a href="javascript:void(0)">API Status</a></li> */}
                    </ul>
                  </div>
                  {/* <!-- End Single Widget --> */}
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  {/* <!-- Single Widget --> */}
                  <div className="footer-widget newsletter">
                    <h5>Subscribe</h5>
                    <p>Subscribe to our newsletter for the latest updates</p>
                    <form action="#" method="get" target="_blank" className="newsletter-form">
                      <input name="EMAIL" placeholder="Email address" required="required" type="email" />
                      <div className="button">
                        <button className="sub-btn">
                          <i className="lni lni-envelope"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <!-- End Single Widget --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--/ End Footer Top --> */}
      </footer>
      {/* <!--/ End Footer Area --> */}

      {/* <div className="made-in-ayroui mt-4" >
        <a href="https://ayroui.com" target="_blank" rel="nofollow">
          <img style={{ width: "220px" }} src={ayroui} />
        </a>
      </div> */}

      <a href="#" className="scroll-top btn-hover">
        <i className="lni lni-chevron-up"></i>
      </a>

      {/* <!--====== js ====== --> */}
      {/* <script src="assets/js/main.js"></script> */}

      {/* <script> */}

      {/* //===== close navbar-collapse when a  clicked
        let navbarTogglerNine = document.querySelector(
        ".navbar-nine .navbar-toggler"
        );
        navbarTogglerNine.addEventListener("click", function () {
          navbarTogglerNine.classList.toggle("active");
    });

        // ==== left sidebar toggle
        let sidebarLeft = document.querySelector(".sidebar-left");
        let overlayLeft = document.querySelector(".overlay-left");
        let sidebarClose = document.querySelector(".sidebar-close .close");

        overlayLeft.addEventListener("click", function () {
          sidebarLeft.classList.toggle("open");
        overlayLeft.classList.toggle("open");
    });
        sidebarClose.addEventListener("click", function () {
          sidebarLeft.classList.remove("open");
        overlayLeft.classList.remove("open");
    });

        // ===== navbar nine sideMenu
        let sideMenuLeftNine = document.querySelector(".navbar-nine .menu-bar");

        sideMenuLeftNine.addEventListener("click", function () {
          sidebarLeft.classList.add("open");
        overlayLeft.classList.add("open");
    }); */}

      {/* //========= glightbox
        GLightbox({
          'href': 'https://www.youtube.com/watch?v=r44RKWyfcFw&fbclid=IwAR21beSJORalzmzokxDRcGfkZA1AtRTE__l5N4r09HcGS5Y6vOluyouM9EM',
        'type': 'video',
        'source': 'youtube', //vimeo, youtube or local
        'width': 900,
        'autoplayVideos': true,
    }); */}

      {/* </script> */}
    </div>
  );
}

export default App;
